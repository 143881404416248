import { useCartData } from "../../providers/cartProvider";
import { Link, NavLink } from "react-router-dom";
import { getPageProperty } from "../../dataManipulation/page";
import "./Nav.css"
import shoppingBagLogo from "/src/assets/svg/shopping-bag.svg";
import menuLogo from "/src/assets/svg/menu.svg";
import { useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import useOutsideClickListener from "@/hooks/useOutsideClickListener";
import { useThemeData } from "@/providers/themeProvider";

const ShoppingBagLogo = ({ quantity }: { quantity: number }) => {
	const { theme } = useThemeData();
	
	return (
		<div className="quick-access-container nav-cart-logo-container">
			<img className="nav-cart-logo" alt="shopping bag logo" src={shoppingBagLogo} />
			<div className="nav-cart-info nav-cart-quantity" style={{ backgroundColor: theme.colors.third}} >{quantity}</div>
		</div>
	);
}

const NavCart = () => {
	const { cartProducts, cartTotalPrice } = useCartData();
	const cartProductTotalQty = cartProducts.reduce((acc, cur) => acc + cur.quantity, 0)
	
	return (
		<div className="nav-cart">
			{cartTotalPrice > 0 && <div className="nav-cart-info nav-cart-price">${cartTotalPrice}</div>}
			<Link to={getPageProperty("Cart").routerUrl}>
				<ShoppingBagLogo
					quantity={cartProductTotalQty}
				/>
			</Link>
		</div>
	);
};

type NavigationLinkProps = {
	page: string,
	hideMenu?: () => void,
}

const NavigationLink = ({ page, hideMenu }: NavigationLinkProps) => {
	const { theme } = useThemeData();
	
	return (
		<NavLink
			to={getPageProperty(page).routerUrl}
			onClick={hideMenu}
			className={({ isActive, isPending }: { isActive: boolean, isPending: boolean }) =>
				"nav-link" + (isActive
					? " active"
					: isPending
						? " pending"
				: "")}
			style={({ isActive }: { isActive: boolean }): React.CSSProperties =>
				isActive ? {
					color: theme.colors.third,
					fontFamily: "FuturaMedium",
				} : {}
			}>
			{page}
		</NavLink>
	)
};

type NavLinksProps = {
	hideMenu?: () => void,
}

const NavLinks = ({ hideMenu }: NavLinksProps) => {

	return (
		<>
			<NavigationLink page="Home" hideMenu={hideMenu} />
			<NavigationLink page="Shop" hideMenu={hideMenu} />
			<a className="nav-link">Blog</a>
			<a className="nav-link">Contact</a>
		</>
	);
}

const NavMenuButton = ({ onClick }: {onClick: () => void}) => {
	return (
		<div className="menu-button quick-access-container" onClick={onClick} >
			<img className="nav-menu-button-logo" alt="menu button logo" src={menuLogo} />
		</div>
	);
}

// TODO: Rename in something like header, but need to change class already named header in the project
const Nav = () => {

	const [isMenuRevealed, setIsMenuRevealed] = useState(false);
	const container = useRef<HTMLDivElement>(null);
	const tl = useRef<GSAPTimeline>();
	const topMenuRef = useRef<HTMLDivElement>(null);
	const { theme } = useThemeData();

	const onToggleMenu = () => {
		if (isMenuRevealed)
			hideMenu();
		else
			revealMenu();
	}

	const { contextSafe } = useGSAP(() => {
		tl.current = gsap.timeline({ paused: true })
			.from(".nav-links.menu", { y: "-100%", duration: 0.3, ease: "power1.inOut" });
	}, { scope: container });

	const revealMenu = contextSafe(() => {
		tl.current?.play()
		setIsMenuRevealed(true);
	});

	const hideMenu = contextSafe(() => {
		tl.current?.reverse()
		setIsMenuRevealed(false);
	});

	const handleClickOutsideMenu = (e: MouseEvent | TouchEvent | KeyboardEvent) => {
		if (isMenuRevealed) {
			e.preventDefault();
			e.stopPropagation();
			hideMenu();
		}
	}

	useOutsideClickListener(topMenuRef, handleClickOutsideMenu)

	return (
		<div ref={container}>
			<div className="nav">
				<Link to="/">
					<div className="nav-icon">Website icon</div>
				</Link>
				<nav className="nav-links inline">
					<NavLinks hideMenu={hideMenu} />
				</nav>
				<div className="quick-access">
					<NavCart />
					<NavMenuButton onClick={onToggleMenu} />
				</div>
			</div>
			<nav className="nav-links menu" ref={topMenuRef} style={{ backgroundColor: theme.colors.main }}>
				<NavLinks hideMenu={hideMenu} />
			</nav>
		</div>
	);
};

export default Nav;
