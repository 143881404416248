import localforage from "localforage";

type imageObj = {
	name: string,
	url: string,
}

const getImage = async (name: string) => {
	let imagesObj = await localforage.getItem<imageObj[]>("images");
	let imageObj = imagesObj?.find(img => img.name === name);
	return imageObj == undefined ? null : imageObj
}

const getImages = async () => {
	let imagesObj = await localforage.getItem<imageObj[]>("images");
  if (!imagesObj) imagesObj = [];
  return imagesObj;
}

const setImages = async (imagesObj: imageObj[] | null) => {
  return localforage.setItem("images", imagesObj);
}

const createImageObj = async (name: string, url: string) => {
	let imageObj = { name: name, url: url} as imageObj;
	let imagesObj = await getImages();
	imagesObj.unshift(imageObj);
	await setImages(imagesObj);
	return imageObj;	
}

export const flushImages = async () => {
	await setImages(null);
}

// const fetchImage = async (name: string): Promise<string> => {
// 	return new Promise(async (resolve) => {
// 		const fetchURL = `/${name}.webp`;

// 		const cachedImage = await getImage(fetchURL);

// 		let imageObjectURL = null;
// 		if (cachedImage) imageObjectURL = cachedImage.url;
// 		else {
// 			const res = await fetch(fetchURL);
// 			const imageBlob = await res.blob();
// 			imageObjectURL = URL.createObjectURL(imageBlob);

// 			await createImageObj(fetchURL, imageObjectURL);
// 		}

// 		resolve(imageObjectURL);
// 	});
// };

const fetchImage = async (name: string): Promise<string> => {
	return new Promise(async (resolve) => {
		setTimeout(async () => {
			const fetchURL = `/${name}.webp`;
			const res = await fetch(fetchURL);
			const imageBlob = await res.blob();
			const imageObjectURL = URL.createObjectURL(imageBlob);

			resolve(imageObjectURL);
		}, 500);
	});
};

export default fetchImage;
