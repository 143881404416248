import { getSubProduct } from "../dataManipulation/products";
import { SubProductObj } from "../dataManipulation/types"
import { createContext, useContext, useMemo, useReducer } from 'react';

export type CartContextType = {
	cartProducts: CartProductObj[],
	getProductOptionsPrice: (product: CartProductObj) => number,
	getCartTotal: () => number,
	addProductToCart: (productId: string, formOptions: FormProductOptionObj, quantity: number) => Promise<CartProductObj | null>,
	changeProductQuantity: (modifier: number, id: string) => void,
	removeFromCart: (id: string) => void,
	clearCart: () => void,
}

export interface CartProductObj {
	id: string;
	product_id: string,
	name: string,
	description: string,
	images_src: { src: string, alt: string }[],
	price: number,
	options: CartProductOptionObj[] | null,
	quantity: number;
}

export interface CartProductOptionObj {
	name: string,
	type: string,
	value: string,
	price: number,
}

export type State = {
	products: CartProductObj[],
	totalPrice: number,
}

export type CartDataContextType = {
	cartProducts: CartProductObj[],
	cartTotalPrice: number,
}

type API = {
	addProductToCart: (productId: string, formOptions: FormProductOptionObj, quantity: number) => void,
	changeProductQuantity: (modifier: number, id: string) => void,
	removeFromCart: (id: string) => void,
	clearCart: () => void,
	getProductOptionsPrice: (product: CartProductObj) => number;
}

export const CartAPIContext = createContext<API>({} as API);
export const CartDataContext = createContext<CartDataContextType>({} as CartDataContextType);

type Actions =
  | { type: "addProduct"; product: CartProductObj }
	| { type: "updateProductQuantity"; id: string; modifier: number }
  | { type: "removeProduct"; id: string }
	| { type: "clearProducts" };

const reducer = (state: State, action: Actions): State => {

	let isItemInCart;
	
	const getCartTotal = (products: CartProductObj[]): number => {
		return products ?
			products.reduce((prd_acc, prd_curr) => {
				const prd_opt_total = getProductOptionsPrice(prd_curr);
				return (prd_acc + ((prd_curr.price + prd_opt_total) * prd_curr.quantity))
			}, 0)
			: 0;
	};

	switch (action.type) {
		case "addProduct":
			const isIdenticalProduct = state.products.some((product) => product.product_id === action.product.product_id &&
				isCartProductSameOptions(product, action.product));
			
			if (isIdenticalProduct) {
				const updatedProducts = state.products.map((product) => {
					if (product.product_id === action.product.product_id && isCartProductSameOptions(product, action.product))
						return { ...product, quantity: product.quantity + 1}
					return product;
				});
				return { ...state, products: updatedProducts, totalPrice: getCartTotal(updatedProducts) };
			}
			const updatedProducts = [...state.products, action.product];
			return { ...state, products: updatedProducts, totalPrice: getCartTotal(updatedProducts)};
		case "updateProductQuantity":
			isItemInCart = state.products.some((product) => product.id === action.id);

			if (isItemInCart) {
				const updatedProducts = state.products.map((product) => {
					if (product.id === action.id) {
						const newQuantity = product.quantity + action.modifier;
						if (newQuantity < 0)
							return { ...product, quantity: 0 }
						return { ...product, quantity: newQuantity }
					}
					return product;
				});
				return { ...state, products: updatedProducts, totalPrice: getCartTotal(updatedProducts) };
			}
			break;
		case "removeProduct":
			isItemInCart = state.products.some((product) => product.id === action.id);

			if (isItemInCart) {
				const updatedProducts = state.products.filter((product) => product.id !== action.id);
				return { ...state, products: updatedProducts, totalPrice: getCartTotal(updatedProducts) };
			}
			break;
		case "clearProducts":
			return { ...state, products: [], totalPrice: 0}
		default:
			break;
	}
	return { ...state }
}

const isCartProductSameOptions = (product1: CartProductObj, product2: CartProductObj) => {
	if (product1 == null || product2 == null) return false;
	if (product1.product_id !== product2.product_id) return false;
	if (!product1.options && !product2.options) return true;
	if (product1.options == null || product2.options == null) return false;
	const unionOptionTypes = [...product1.options, ...product2.options].map((option) => option.type);
	const uniqOptionTypes = unionOptionTypes.filter((type, i) => unionOptionTypes.indexOf(type) == i);
	return uniqOptionTypes.every((type) => {
		const val1 = product1.options!.find((opt1) => opt1.type === type)?.value;
		const val2 = product2.options!.find((opt2) => opt2.type === type)?.value;
		return val1 === val2;
	}
	)
}

export const getProductOptionsPrice = (product: CartProductObj): number => {
	return (product.options ?
		product.options.reduce((acc, curr) => (acc + curr.price), 0)
		: 0);
};

const getProductOptions = (product: SubProductObj, options: FormProductOptionObj): (CartProductOptionObj[] | null) => {
	if (!product.options) return null;
	const cartOptions = product.options.map((prodOption) => {
		if (options[prodOption.type] !== undefined) {
			const optionValue = options[prodOption.type];
			const optionPrice = prodOption.values.find((prodOptionVal) => options[prodOption.type] === prodOptionVal.name)?.price;
			if (optionValue === undefined || optionPrice == undefined) return undefined
			return ({
				name: prodOption.name,
				type: prodOption.type,
				value: optionValue,
				price: optionPrice,
			} as CartProductOptionObj);
		}
	}).filter((e) => e !== undefined) as CartProductOptionObj[];
	return cartOptions;
};

export type FormProductOptionObj = { [type: string]: string }

export const CartProvider = ({ children }: { children?: React.ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, { products: [], totalPrice: 0 } as State);

	const api = useMemo(() => {
		const addProductToCart = async (product_id: string, formOptions: FormProductOptionObj, quantity: number) => {
			const product = await getSubProduct(product_id);

			if (product) {
				let { sharedOptions, options, ...rest } = product;
				const productOptions = getProductOptions(product, formOptions);
				const newProduct = Object.assign(rest, {
					id: Math.random().toString(36).substring(2, 9),
					product_id: product_id,
					options: productOptions || null,
					quantity: quantity,
				});

				dispatch({ type: "addProduct", product: newProduct });
			}
		};

		const changeProductQuantity = (modifier: number, id: string): void => dispatch({ type: "updateProductQuantity", id: id, modifier: modifier });

		const removeFromCart = (id: string): void => dispatch({ type: "removeProduct", id: id });

		const clearCart = (): void => dispatch({ type: "clearProducts" });

		return { addProductToCart, changeProductQuantity, removeFromCart, clearCart, getProductOptionsPrice };
	}, []);

	return (
		<CartAPIContext.Provider value={api}>
			<CartDataContext.Provider value={{
				cartProducts: state.products,
				cartTotalPrice: state.totalPrice
			}}>
				{children}
			</CartDataContext.Provider>
		</CartAPIContext.Provider>
	);
}

export const useCartAPI = () => useContext(CartAPIContext);
export const useCartData = () => useContext(CartDataContext);
