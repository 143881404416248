import React, { useState } from "react";
import InputRadio from "../inputRadio";

const SizeSelection = ({ optionValues }: { optionValues: { name: string, price: number }[] }) => {
	const sizes = [37, 38, 39, 40, 41, 42, 43, 44, 45];
	const [curSize, setCurSize] = useState(Number(optionValues[0].name));

	const onOptionSelection = (name: number) => {
		setCurSize(name);
	}

	return (
		<div className="Description-property" id="PD-sizes">
			<h4 className="Product-description-attribute-name">Size:</h4>
			<div id="size-options" className="option-list">
				{sizes.map((size, i) => {
					const optionState = curSize === size ? "current" : optionValues.some((option) => Number(option.name) === size) ? "available" : "not-available";
					return (
						<React.Fragment key={i}>
							<InputRadio<number>
								status={optionState}
								formPropertyName="size"
								value={size}
								onSelection={onOptionSelection}
							/>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
}

export default SizeSelection;
