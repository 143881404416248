import localforage from "localforage";
import { matchSorter } from "match-sorter";
import { sortBy } from 'sort-by-typescript';
import { ProductObj, ProductInfoObj, SubProductObj } from './types'

export const getProduct = async (id: string) => {
  let products = await localforage.getItem<ProductObj[]>("products");
  let product = products?.find(product => product.id === id);
  return product ?? null;
}

export const getSubProduct = async (id: string) => {
	const products = await localforage.getItem<ProductObj[]>("products");
	const subProducts = products?.flatMap((products) => products.derivates);
	const subProduct = subProducts?.find((subP) => subP.id === id)
	return subProduct;
}

export const getSubProducts = async () => {
	const products = await localforage.getItem<ProductObj[]>("products");
	let subProducts = products?.flatMap((products) => products.derivates);
	if (!subProducts) subProducts = [];
	return subProducts.sort(sortBy("last", "createdAt"));
}

export const getProducts = async (query?: string) => {
  let products = await localforage.getItem<ProductObj[]>("products");
  if (!products) products = [];
  if (query) {
    products = matchSorter(products, query, { keys: ["first", "last"] });
  }
  return products.sort(sortBy("last", "createdAt"));
}

export const createProducts = async (newProducts: ProductInfoObj[]) => {
	for (var i = 0; i < newProducts.length; i++) {
		await createProduct(newProducts[i]);		
	}
}

export const createProduct = async (newProduct: ProductInfoObj) => {
	let id = Math.random().toString(36).substring(2, 9);
	let product = Object.assign(newProduct, {
		id,
		createdAt: Date.now() - Math.floor(Math.random() * 31536000), // TODO: this is a hack to simulate different creation date (from now - [0-1year])
	}) as ProductObj;
	const derivatesWithId = product?.derivates?.map((subP) => Object.assign(subP, {id: Math.random().toString(36).substring(2, 9), createdAt: product.createdAt, parentId: id }) as SubProductObj);
	product.derivates = derivatesWithId;
	let products = await getProducts();
	products.unshift(product);
	await set(products);
	return product;
}

export const getProductSharedProperty = (parent: ProductObj, type: string) => {
	return {
		name: type, idAndValues: parent.derivates.map((subP) => {
			const optionValue = subP.sharedOptions.find((option) => option.type === type);
			if (optionValue !== undefined) {
				return ({ id: subP.id, value: optionValue.value })
			}
		}).filter((subP) => subP !== undefined)
	}
}

function set(products: ProductObj[] | null) {
  return localforage.setItem("products", products);
}

export const flushProduct = async () => {
	await set(null);
}
