import Placeholder from "@/utils/placeholder";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { useThemeData } from "@/providers/themeProvider";

export const ProductDescriptionLargePlaceholder = () => {
	const { theme } = useThemeData();

	const mainColor = theme.colors.fourth;
	const secColor = theme.colors.main;

	const container = useRef<HTMLDivElement>(null);
	const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
	const animInitStyle = { background: `linear-gradient(66deg, ${mainColor} -30%, ${secColor} -15%, ${mainColor} 0%)` };
	const animatedClasses = [
		".PD-img-wrapper",
		".gallery-image-wrapper",
		".PD-name>span",
		".PD-review>span",
		".PD-description>span",
		".PD-large-zoneC>span",
	]

	useGSAP(() => {
		gsap.effects.fadeIn(".PD-container.large.placeholder", { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { revertOnUpdate: true })

	useGSAP(() => {
		tl.to(animatedClasses, { background: `linear-gradient(66deg, ${mainColor} 100%, ${secColor} 115%, ${mainColor} 130%)`, duration: 1.7, ease: "power1.inOut" })
	}, { scope: container, revertOnUpdate: true })

	return (
		<div className="PD-container large placeholder" style={{ visibility: "hidden" }} ref={container}>
			<div className="PD-large-zoneA">
				<div>
					<h2 className="PD-name">
						<Placeholder sizes={[2, 3]} unit={"em"} style={animInitStyle} />
					</h2>
					<p className="PD-review">
						<Placeholder sizes={[90]} unit={"%"} style={animInitStyle} />
					</p>
					<p className="PD-description">
						<Placeholder sizes={[75, 20, 20, 60, 50]} unit={"%"} style={animInitStyle} />
					</p>
				</div>
				<div>
					<div className="gallery">
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
					</div>
				</div>
			</div>
			<div className="PD-large-zoneB">
				<div className="PD-img-wrapper" style={animInitStyle}></div>
			</div>
			<div className="PD-large-zoneC">
				<div></div>
				<Placeholder sizes={[100]} unit={"%"} style={animInitStyle} />
			</div>
		</div>
	);
}

export const ProductDescriptionMediumPlaceholder = () => {
	const { theme } = useThemeData();

	const mainColor = theme.colors.fourth;
	const secColor = theme.colors.main;

	const container = useRef<HTMLDivElement>(null);
	const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
	const animInitStyle = { background: `linear-gradient(66deg, ${mainColor} -30%, ${secColor} -15%, ${mainColor} 0%)` };
	const animatedClasses = [
		".PD-img-wrapper",
		".gallery-image-wrapper",
		".PD-name>span",
		".PD-review>span",
		".PD-description>span",
		".PD-medium-zoneC>span",
	]

	useGSAP(() => {
		gsap.effects.fadeIn(".PD-container.medium.placeholder", { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { revertOnUpdate: true })

	useGSAP(() => {
		tl.to(animatedClasses, { background: `linear-gradient(66deg, ${mainColor} 100%, ${secColor} 115%, ${mainColor} 130%)`, duration: 1.7, ease: "power1.inOut" })
	}, { scope: container, revertOnUpdate: true })

	return (
		<div className="PD-container medium placeholder" style={{ visibility: "hidden" }} ref={container}>
			<div className="PD-medium-zoneA">
				<div>
					<h2 className="PD-name">
						<Placeholder sizes={[2, 3]} unit={"em"} style={animInitStyle} />
					</h2>
					<p className="PD-review">
						<Placeholder sizes={[90]} unit={"%"} style={animInitStyle} />
					</p>
					<p className="PD-description">
						<Placeholder sizes={[75, 20, 20, 60, 50]} unit={"%"} style={animInitStyle} />
					</p>
				</div>
				<div className="PD-add-to-cart">
					<Placeholder sizes={[100]} unit={"%"} style={animInitStyle} />
				</div>
			</div>
			<div className="PD-medium-zoneB">
				<div className="PD-img-wrapper" style={animInitStyle}></div>
				<div>
					<div className="gallery">
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
						<div className="gallery-image-wrapper" style={animInitStyle}></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export const ProductDescriptionSmallPlaceholder = () => {
	const { theme } = useThemeData();

	const mainColor = theme.colors.fourth;
	const secColor = theme.colors.main;

	const container = useRef<HTMLDivElement>(null);
	const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
	const animInitStyle = { background: `linear-gradient(66deg, ${mainColor} -30%, ${secColor} -15%, ${mainColor} 0%)` };
	const animatedClasses = [
		".PD-img-wrapper",
		".gallery-image-wrapper",
		".PD-name>span",
		".PD-review>span",
		".PD-description>span",
		".PD-small-zoneC>span",
	]

	useGSAP(() => {
		gsap.effects.fadeIn(".PD-container.small.placeholder", { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { revertOnUpdate: true })

	useGSAP(() => {
		tl.to(animatedClasses, {
			background: `linear-gradient(66deg, ${mainColor} 100%, ${secColor} 115%, ${mainColor} 130%)`,
			duration: 1.7,
			ease: "power1.inOut",
		})
	}, { scope: container, revertOnUpdate: true })

	return (
		<div className="PD-container small placeholder" style={{ visibility: "hidden" }} ref={container}>
			<div className="PD-small-zoneA">
				<h2 className="PD-name">
					<Placeholder sizes={[2, 3]} unit={"em"} style={animInitStyle} />
				</h2>
				<div className="PD-images">
					<div className="PD-img-wrapper" style={animInitStyle}></div>
					<div>
						<div className="gallery">
							<div className="gallery-image-wrapper" style={animInitStyle}></div>
							<div className="gallery-image-wrapper" style={animInitStyle}></div>
							<div className="gallery-image-wrapper" style={animInitStyle}></div>
						</div>
					</div>
				</div>
			</div>
			<div className="PD-small-zoneB">
				<p className="PD-review">
					<Placeholder sizes={[90]} unit={"%"} style={animInitStyle} />
				</p>
				<p className="PD-description">
					<Placeholder sizes={[75, 20, 50]} unit={"%"} style={animInitStyle} />
				</p>
				<div className="PD-add-to-cart">
					<Placeholder sizes={[100]} unit={"%"} style={animInitStyle} />
				</div>
			</div>
		</div>
	);
}
