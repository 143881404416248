import React from "react";
import { SubProductObj } from "../../dataManipulation/types";
import SizeSelection from "./SizeSelection";


interface OptionSelectionProps {
	product: SubProductObj,
}

const OptionSelection = ({ product }: OptionSelectionProps) => {
	return (
		<>
			{product.options?.map((option, i) => {
				switch (option.type) {
					case 'size':
						return (<React.Fragment key={i}><SizeSelection optionValues={option.values} /></React.Fragment>);
					default:
						console.log("No option type specified for " + product.name + " with option " + option.name);
				}
			}
			)}
		</>
	);
}

export default OptionSelection;
