import { createContext, useContext, useEffect, useMemo, useReducer } from "react";

const defaultProductPerPage = 12;

export type PaginationAPIContextType = {
	nextPage: () => void,
	previousPage: () => void,
	changeSortingType: (type: string) => void,
	changePageFromCurrent: (pageShiftNbr: number) => void,
	setPage: (pageNbr: number) => void,
}

export type PaginationDataContextType<T> = {
	pageProducts: T[],
	pageNbr: number,
	productPerPage: number,
	pageProductRange: { min: number, max: number },
	productsNbr: number,
	hasPreviousPage: boolean,
	hasNextPage: boolean,
	curSortType: string | undefined,
	allSortTypes: string[] | undefined,
}

const PaginationAPIContext = createContext<PaginationAPIContextType>({} as PaginationAPIContextType);
const PaginationDataContext = createContext<PaginationDataContextType<any>>({} as PaginationDataContextType<any>);

type ISortingOption<T> = {
	name: string,
	fct: (products: T[]) => T[],
}

type State<T> = {
	allProducts: T[],
	allProductsNbr: number,
	pageProducts: T[],
	pageNbr: number,
	productPerPage: number,
	pageProductRange: { min: number, max: number },
	hasPreviousPage: boolean,
	hasNextPage: boolean,
	sortTypes: string[] | undefined,
	sortingOption: ISortingOption<T> | undefined,
	sortingOptions: ISortingOption<T>[] | undefined,
}


const getPageProduct = <T,>(allProducts: T[], productPerPage: number, pageNbr: number) => {
	return (allProducts.filter((_, i) => i >= (pageNbr * productPerPage) &&
		i < ((pageNbr + 1) * productPerPage)))
}

const getPageProductRange = <T,>(allProducts: T[], productPerPage: number, page: number) => {
	const pastProductNbr = page * productPerPage;

	return ({
		min: pastProductNbr + 1,
		max: pastProductNbr + ((page + 1) * productPerPage < allProducts.length ?
			productPerPage
			:
			allProducts.length % productPerPage
		)
	})
}

const checkHasPrevNextPage = <T,>(allProducts: T[], productPerPage: number, pageNbr: number) => {
	const hasProdInPrevPage = getPageProduct(allProducts, productPerPage, pageNbr - 1).length !== 0;
	const hasProdInNextPage = getPageProduct(allProducts, productPerPage, pageNbr + 1).length !== 0;
	return { hasPrevPage: hasProdInPrevPage, hasNextPage: hasProdInNextPage }
}

const getSortingFct = <T,>(sortingOptions: ISortingOption<T>[] | undefined, name: string | undefined) => {
	if (sortingOptions && name) {
		const sortingFct = sortingOptions.find((sortOpt) => sortOpt.name === name);
		if (sortingFct)
			return sortingFct.fct
	}
	return ((p) => p) as ISortingOption<T>["fct"];
}

type Actions<T> =
  | { type: "updateAllProducts"; products: State<T>["allProducts"] }
  | { type: "updatePageRelative"; pageModifier: number }
  | { type: "setPage"; pageNbr: number }
  | { type: "updateProductPerPage"; productPerPage: number }
  | { type: "updateSortType"; name: string };

const reducer = <T,>(state: State<T>, action: Actions<T>): State<T> => {

	let allSortedProducts = [];
	let newPageNbr = 0;
	let newPageProduct = [];
	let newPageProductRange = {} as State<T>["pageProductRange"];
	let hasPrevNextPage = {} as { hasPrevPage: boolean, hasNextPage: boolean };
	let newSortingFct = ((p)=> p) as ISortingOption<T>["fct"];

  switch (action.type) {
		case "updateAllProducts":
			newSortingFct = getSortingFct(state.sortingOptions, state.sortingOption?.name)
			allSortedProducts = newSortingFct(action.products);
			newPageProduct = getPageProduct(allSortedProducts, state.productPerPage, 0)
			hasPrevNextPage = checkHasPrevNextPage(state.allProducts, state.productPerPage, 0);
			newPageProductRange = getPageProductRange(state.allProducts, state.productPerPage, newPageNbr);
			return { ...state,
							 allProducts: allSortedProducts,
							 pageProducts: newPageProduct,
							 pageNbr: 0,
							 hasPreviousPage: hasPrevNextPage.hasPrevPage,
							 hasNextPage: hasPrevNextPage.hasNextPage,
							 pageProductRange: newPageProductRange };
		case "updatePageRelative":
			newPageNbr = state.pageNbr + action.pageModifier;
			if (newPageNbr < 0 || newPageNbr > (state.allProducts.length / state.productPerPage))
				break;
			newPageProductRange = getPageProductRange(state.allProducts, state.productPerPage, newPageNbr);
			newPageProduct = getPageProduct(state.allProducts, state.productPerPage, newPageNbr);
			if (!newPageProduct)
				break;
			hasPrevNextPage = checkHasPrevNextPage(state.allProducts, state.productPerPage, newPageNbr);
			return { ...state,
							 pageNbr: newPageNbr,
							 pageProducts: newPageProduct,
							 pageProductRange: newPageProductRange,
						 	 hasPreviousPage: hasPrevNextPage.hasPrevPage,
							 hasNextPage: hasPrevNextPage.hasNextPage };
		case "setPage":
			newPageNbr = action.pageNbr;
			if (newPageNbr < 0 || newPageNbr > (state.allProducts.length / state.productPerPage))
				break;
			newPageProductRange = getPageProductRange(state.allProducts, state.productPerPage, newPageNbr);
			newPageProduct = getPageProduct(state.allProducts, state.productPerPage, newPageNbr);
			if (!newPageProduct)
				break;
			hasPrevNextPage = checkHasPrevNextPage(state.allProducts, state.productPerPage, newPageNbr);
			return { ...state,
							 pageNbr: newPageNbr,
							 pageProducts: newPageProduct,
							 pageProductRange: newPageProductRange,
						 	 hasPreviousPage: hasPrevNextPage.hasPrevPage,
							 hasNextPage: hasPrevNextPage.hasNextPage };
		case "updateProductPerPage":
			return { ...state, productPerPage: action.productPerPage };
		case "updateSortType":
			newSortingFct = getSortingFct(state.sortingOptions, action.name)
			allSortedProducts = newSortingFct(state.allProducts);
			newPageProduct = getPageProduct(allSortedProducts, state.productPerPage, 0)
			hasPrevNextPage = checkHasPrevNextPage(state.allProducts, state.productPerPage, 0);
			newPageProductRange = getPageProductRange(state.allProducts, state.productPerPage, newPageNbr);
			return { ...state,
							 sortingOption: { name: action.name, fct: newSortingFct },
							 allProducts: allSortedProducts,
							 pageProducts: newPageProduct,
							 pageNbr: 0,
							 hasPreviousPage: hasPrevNextPage.hasPrevPage,
							 hasNextPage: hasPrevNextPage.hasNextPage,
							 pageProductRange: newPageProductRange };
  }
	return {...state};
};

const updateStateFromProps = <T,>(
	products: State<T>["allProducts"],
	productPerPage: number,
	defaultSort: string | undefined,
	sortingOptions: ISortingOption<T>[] | undefined) => {

	const pageProducts = getPageProduct(products, productPerPage, 0);
	const hasProductNextPage = products.length > productPerPage;
	const sortTypes = sortingOptions?.map((option) => option.name);
	const defaultSortOption = !sortingOptions ?
		undefined
		:
		sortingOptions.some((type) => type.name === defaultSort) ?
			sortingOptions.find((type) => type.name === defaultSort)
			:
			sortingOptions[0];

	return {
		allProducts: products,
		allProductsNbr: products.length,
		pageNbr: 0,
		pageProducts: pageProducts,
		productPerPage: productPerPage,
		pageProductRange: { min: 1, max: productPerPage },
		hasPreviousPage: false,
		hasNextPage: hasProductNextPage,
		sortTypes: sortTypes,
		sortingOption: defaultSortOption,
		sortingOptions: sortingOptions,
	}
}

interface PaginationProviderProps<T> {
	children: React.ReactNode,
	newProducts: T[],
	productPerPage?: number,
	defaultSort?: string,
	sortingOptions?: ISortingOption<T>[],
}

export const PaginationProvider = <T,>({ children, newProducts, productPerPage = defaultProductPerPage, defaultSort = "", sortingOptions }: PaginationProviderProps<T>) => {

	const [state, dispatch] = useReducer<React.Reducer<State<T>, Actions<T>>>(reducer, updateStateFromProps<T>(newProducts, productPerPage, defaultSort, sortingOptions));

	const api = useMemo(() => {
		const nextPage = () => dispatch({ type: "updatePageRelative", pageModifier: 1 });

		const previousPage = () => dispatch({ type: "updatePageRelative", pageModifier: -1 });

		const changePageFromCurrent = (pageShiftNbr: number) => dispatch({ type: "updatePageRelative", pageModifier: pageShiftNbr });

		const setPage = (pageNbr: number) => dispatch({ type: "setPage", pageNbr: pageNbr });

		const changeSortingType = (type: string) => dispatch({ type: "updateSortType", name: type })

		return { nextPage, previousPage, changePageFromCurrent, setPage, changeSortingType };
	}, []);

	useEffect(() => {
		dispatch({ type: "updateAllProducts", products: newProducts })
	}, [newProducts]);

	useEffect(() => {
		dispatch({ type: "updateProductPerPage", productPerPage: productPerPage})
	}, [productPerPage]);

	useEffect(() => {
		dispatch({ type: "updateSortType", name: defaultSort})
	}, [defaultSort]);

	return (
		<PaginationAPIContext.Provider value={api}>
			<PaginationDataContext.Provider value={{
				pageProducts: state.pageProducts,
				pageNbr: state.pageNbr,
				productPerPage: state.productPerPage,
				pageProductRange: state.pageProductRange,
				productsNbr: state.allProductsNbr,
				hasPreviousPage: state.hasPreviousPage,
				hasNextPage: state.hasNextPage,
				curSortType: state.sortingOption?.name,
				allSortTypes: state.sortTypes,
			}} >
				{children}
			</PaginationDataContext.Provider>
		</PaginationAPIContext.Provider>
	);
}

export const usePaginationAPI = () => useContext(PaginationAPIContext);
// We could split the Data into seperated context to avoid rerender everything when on state variable change but
// the methods update all these variables via the state.
export const usePaginationData = () => useContext(PaginationDataContext);
