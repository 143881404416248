import ProductCardList from "../components/productList/ProductList";
import { createProducts, getSubProducts } from "../dataManipulation/products";
import { ProductObj, SubProductObj } from "../dataManipulation/types"
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { productList } from "@/data/productListTemplateMuji";
import { useLoaderData } from "react-router-dom";
import BreadcrumbProvider from "../providers/breadcrumbProvider";
import { PaginationProvider, usePaginationAPI, usePaginationData } from "../providers/paginationProvider";
import { NavigationButtons } from "../components/pagination/Navigation";
import "./Shop.css"
import "./page.css"
import { Accordion } from "../components/accordion/Accordion";
import ScrollMe from "@/components/scrollMe/ScrollMe";
import { useEffect, useRef } from "react";
import { productsSortingOptions } from "@/dataManipulation/sortProducts";
import { useThemeData } from "@/providers/themeProvider";

export const loader = async () => {
	let products = await getSubProducts();
	if (products.length == 0) {
		await createProducts(productList);
		products = await getSubProducts();
	}
	return (products);
}

const ShopContent = () => {
	const { curSortType, allSortTypes } = usePaginationData();
	const { changeSortingType } = usePaginationAPI();
	const ref = useRef<HTMLDivElement>(null)
	const { theme } = useThemeData();

	useEffect(() => {
		ref.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
	})

	return (
		<>
			<div className="page-background" style={{ backgroundColor: theme.colors.third }}></div>
			<ScrollMe />
			<div className="header">
				<Breadcrumb />
				<h2 className="title">Shop</h2>
			</div>
			<div className='page-content'>
				<div className="shop-page-controls">
					<div className="product-page-info">
						<h5>Sorting: </h5>
						{curSortType && allSortTypes &&
							<Accordion<string>
								items={allSortTypes}
								onSelection={changeSortingType}
								defaultItem={curSortType}
							/>
						}
					</div>
					<NavigationButtons />
				</div>
				<div className="scroll-content" ref={ref}>
					<ProductCardList />
				</div>
			</div >
		</>
	);
}

const Shop = () => {
	const allProducts = useLoaderData() as SubProductObj[];

	return (
		<div className="Shop page">
			<PaginationProvider<SubProductObj>
				newProducts={allProducts}
				productPerPage={12}
				defaultSort="High to low"
				sortingOptions={productsSortingOptions}
			>
				<BreadcrumbProvider page={"Shop"}>
					<ShopContent />
				</BreadcrumbProvider>
			</PaginationProvider>
		</div>
	);
}

export default Shop;
