export const productList = [
	{
		name: "Court Sneakers",
		derivates: [
			{
				name: "Court Sneakers White",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "court_sneakers/white/court_sneakers_white_6", alt: "Court Sneakers White main view" },
					{ src: "court_sneakers/white/court_sneakers_white_1", alt: "Court Sneakers White from above view" },
					{ src: "court_sneakers/white/court_sneakers_white_2", alt: "Court Sneakers White from under view" },
					{ src: "court_sneakers/white/court_sneakers_white_3", alt: "Court Sneakers White back view" },
					{ src: "court_sneakers/white/court_sneakers_white_4", alt: "Court Sneakers White front view" },
					{ src: "court_sneakers/white/court_sneakers_white_5", alt: "Court Sneakers White context view" },
				],
				price: 61,
				review: 1.7,
				review_nbr: 82,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#ffffff",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "38", price: 0 },
							{ name: "41", price: 0 },
							{ name: "42", price: 0 },
							{ name: "43", price: 0 },
							{ name: "44", price: 0 },
							{ name: "45", price: 0 },
						],
						default_value: "40",
					},
				],
			},
		]
	},
	{
		name: "Cushioned Sneakers",
		derivates: [
			{
				name: "Cushioned Sneakers Black",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_7", alt: "court sneakers black main view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_1", alt: "court sneakers black side view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_2", alt: "court sneakers black from under view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_3", alt: "court sneakers black back view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_4", alt: "court sneakers black front view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_5", alt: "court sneakers black inside view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_6", alt: "court sneakers black zoom view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_8", alt: "court sneakers black context 1 view" },
					{ src: "cushioned_sneakers/black/cushioned_sneakers_black_9", alt: "Court Sneakers Black context 2 view" },
				],
				price: 156,
				review: 4.6,
				review_nbr: 31,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "40", price: 0 },
							{ name: "41", price: 0 },
							{ name: "42", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Cushioned Sneakers Black Sole",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_6", alt: "Court Sneakers Black Sole main view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_1", alt: "Court Sneakers Black Sole side view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_2", alt: "Court Sneakers Black Sole from under view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_3", alt: "Court Sneakers Black Sole back view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_4", alt: "Court Sneakers Black Sole front view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_5", alt: "Court Sneakers Black Sole inside view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_7", alt: "Court Sneakers Black context 1 view" },
					{ src: "cushioned_sneakers/black_sole/cushioned_sneakers_black_sole_8", alt: "Court Sneakers Black context 2 view" },
				],
				price: 199,
				review: 2.8,
				review_nbr: 211,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "40", price: 0 },
							{ name: "43", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Cushioned Sneakers Off White",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_7", alt: "Court Sneakers Off White main view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_1", alt: "Court Sneakers Off White side view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_2", alt: "Court Sneakers Off White from under view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_3", alt: "Court Sneakers Off White back view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_4", alt: "Court Sneakers Off White front view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_5", alt: "Court Sneakers Off White inside view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_6", alt: "Court Sneakers Off White zoom view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_8", alt: "Court Sneakers Off White context 1 view" },
					{ src: "cushioned_sneakers/off_white/cushioned_sneakers_off_white_9", alt: "Court Sneakers Off White context 2 view" },
				],
				price: 224,
				review: 4.9,
				review_nbr: 186,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#e2ddd2",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "42", price: 0 },
							{ name: "43", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Cushioned Sneakers Olive Green",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_9", alt: "Court Sneakers Olive Green main view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_2", alt: "Court Sneakers Olive Green side view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_1", alt: "Court Sneakers Olive Green from above view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_3", alt: "Court Sneakers Olive Green from under view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_4", alt: "Court Sneakers Olive Green back view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_6", alt: "Court Sneakers Olive Green front view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_5", alt: "Court Sneakers Olive Green zoom view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_7", alt: "Court Sneakers Olive Green context 1 view" },
					{ src: "cushioned_sneakers/olive_green/cushioned_sneakers_olive_green_8", alt: "Court Sneakers Olive Green context 2 view" },
				],
				price: 258,
				review: 4.7,
				review_nbr: 18,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#6b5b44",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "40", price: 0 },
							{ name: "42", price: 0 },
						],
						default_value: "40",
					},
				],
			},
		]
	},
	{
		name: "Less Tiring Slip-On Sneakers",
		derivates: [
			{
				name: "Less Tiring Slip-On Sneakers Off White",
				description: "Comfortable and casual canvas slip-on sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_9", alt: "Slip-On Sneakers Off White main view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_1", alt: "Slip-On Sneakers Off White side view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_2", alt: "Slip-On Sneakers Off White from under view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_3", alt: "Slip-On Sneakers Off White back view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_4", alt: "Slip-On Sneakers Off White front view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_5", alt: "Slip-On Sneakers Off White inside view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_6", alt: "Slip-On Sneakers Off White zoom view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_7", alt: "Slip-On Sneakers Off White context 1 view" },
					{ src: "less_tiring_slip-on_sneakers/off_white/less_tiring_slip-on_sneakers_off_white_8", alt: "Slip-On Sneakers Off White context 2 view" },
				],
				price: 251,
				review: 4.4,
				review_nbr: 34,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#e2ddd2",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "41", price: 0 },
							{ name: "42", price: 0 },
							{ name: "43", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Slip-On Sneakers Black",
				description: "Comfortable and casual canvas slip-on sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_9", alt: "Slip-On Sneakers Black main view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_1", alt: "Slip-On Sneakers Black side view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_2", alt: "Slip-On Sneakers Black from under view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_3", alt: "Slip-On Sneakers Black back view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_4", alt: "Slip-On Sneakers Black front view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_5", alt: "Slip-On Sneakers Black inside view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_6", alt: "Slip-On Sneakers Black zoom view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_7", alt: "Slip-On Sneakers Black context 1 view" },
					{ src: "less_tiring_slip-on_sneakers/black/less_tiring_slip-on_sneakers_black_8", alt: "Slip-On Sneakers Black context 2 view" },
				],
				price: 94,
				review: 0.2,
				review_nbr: 117,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "41", price: 0 },
							{ name: "43", price: 0 },
							{ name: "44", price: 0 },
							{ name: "45", price: 0 },
						],
						default_value: "40",
					},
				],
			},
		]
	},
	{
		name: "Less Tiring Sneakers",
		derivates: [
			{
				name: "Less Tiring Sneakers Black",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_8", alt: "Less Tiring Sneakers Black main view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_4", alt: "Less Tiring Sneakers Black side view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_3", alt: "Less Tiring Sneakers Black from above view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_5", alt: "Less Tiring Sneakers Black from under view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_6", alt: "Less Tiring Sneakers Black back view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_7", alt: "Less Tiring Sneakers Black front view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_1", alt: "Less Tiring Sneakers Black context 1 view" },
					{ src: "less_tiring_sneakers/black/less_tiring_sneakers_black_2", alt: "Less Tiring Sneakers Black context 2 view" },
				],
				price: 64,
				review: 2.7,
				review_nbr: 260,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "41", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Charcoal Gray",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_8", alt: "Less Tiring Sneakers Charcoal Gray main view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_4", alt: "Less Tiring Sneakers Charcoal Gray side view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_3", alt: "Less Tiring Sneakers Charcoal Gray from above view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_5", alt: "Less Tiring Sneakers Charcoal Gray from under view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_6", alt: "Less Tiring Sneakers Charcoal Gray back view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_7", alt: "Less Tiring Sneakers Charcoal Gray front view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_1", alt: "Less Tiring Sneakers Charcoal Gray context 1 view" },
					{ src: "less_tiring_sneakers/charcoal_gray/less_tiring_sneakers_charcoal_gray_2", alt: "Less Tiring Sneakers Charcoal Gray context 2 view" },
				],
				price: 71,
				review: 2.0,
				review_nbr: 140,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#bbb1b1",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "39", price: 0 },
							{ name: "41", price: 0 },
							{ name: "42", price: 0 },
							{ name: "43", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Gray Pattern",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_9", alt: "Less Tiring Sneakers Gray Pattern main view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_11", alt: "Less Tiring Sneakers Gray Pattern side view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_10", alt: "Less Tiring Sneakers Gray Pattern from above view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_1", alt: "Less Tiring Sneakers Gray Pattern from under view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_2", alt: "Less Tiring Sneakers Gray Pattern back view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_3", alt: "Less Tiring Sneakers Gray Pattern front view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_4", alt: "Less Tiring Sneakers Gray Pattern inside view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_5", alt: "Less Tiring Sneakers Gray Pattern zoom 1 view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_6", alt: "Less Tiring Sneakers Gray Pattern zoom 2 view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_7", alt: "Less Tiring Sneakers Gray Pattern context 1 view" },
					{ src: "less_tiring_sneakers/gray_pattern/less_tiring_sneakers_gray_pattern_8", alt: "Less Tiring Sneakers Gray Pattern context 2 view" },
				],
				price: 138,
				review: 2.1,
				review_nbr: 10,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#bbb1b1",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "42", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Light Beige",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_8", alt: "Less Tiring Sneakers Light Beige main view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_4", alt: "Less Tiring Sneakers Light Beige side view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_3", alt: "Less Tiring Sneakers Light Beige from above view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_5", alt: "Less Tiring Sneakers Light Beige from under view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_6", alt: "Less Tiring Sneakers Light Beige back view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_7", alt: "Less Tiring Sneakers Light Beige front view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_1", alt: "Less Tiring Sneakers Light Beige context 1 view" },
					{ src: "less_tiring_sneakers/light_beige/less_tiring_sneakers_light_beige_2", alt: "Less Tiring Sneakers Light Beige context 2 view" },
				],
				price: 235,
				review: 4.8,
				review_nbr: 32,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#eed9bd",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "42", price: 0 },
							{ name: "45", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Light Purple",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_8", alt: "Less Tiring Sneakers Light Purple main view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_4", alt: "Less Tiring Sneakers Light Purple side view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_3", alt: "Less Tiring Sneakers Light Purple from above view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_5", alt: "Less Tiring Sneakers Light Purple from under view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_6", alt: "Less Tiring Sneakers Light Purple back view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_7", alt: "Less Tiring Sneakers Light Purple front view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_1", alt: "Less Tiring Sneakers Light Purple context 1 view" },
					{ src: "less_tiring_sneakers/light_purple/less_tiring_sneakers_light_purple_2", alt: "Less Tiring Sneakers Light Purple context 2 view" },
				],
				price: 181,
				review: 3.4,
				review_nbr: 271,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#c9add7",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "39", price: 0 },
							{ name: "40", price: 0 },
							{ name: "42", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Natural",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_8", alt: "Less Tiring Sneakers Natural main view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_4", alt: "Less Tiring Sneakers Natural side view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_3", alt: "Less Tiring Sneakers Natural from above view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_5", alt: "Less Tiring Sneakers Natural from under view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_6", alt: "Less Tiring Sneakers Natural back view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_7", alt: "Less Tiring Sneakers Natural front view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_1", alt: "Less Tiring Sneakers Natural context 1 view" },
					{ src: "less_tiring_sneakers/natural/less_tiring_sneakers_natural_2", alt: "Less Tiring Sneakers Natural context 2 view" },
				],
				price: 90,
				review: 2.0,
				review_nbr: 180,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#eed9bd",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "45", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Less Tiring Sneakers Off White",
				description: "MUJI has mastered its art of minimalistic design, bringing our signature no logo concept to rework the classic Reebok Club C 85. The reworked shoe features a soft leather outer and a terry cloth inner as well as Reebok's DMX microbubble technology in the soles. The heel has a loop that allows for effortless wearing.",
				images_src: [
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_8", alt: "Less Tiring Sneakers Off White main view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_4", alt: "Less Tiring Sneakers Off White side view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_3", alt: "Less Tiring Sneakers Off White from above view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_5", alt: "Less Tiring Sneakers Off White from under view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_6", alt: "Less Tiring Sneakers Off White back view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_7", alt: "Less Tiring Sneakers Off White front view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_1", alt: "Less Tiring Sneakers Off White context 1 view" },
					{ src: "less_tiring_sneakers/off_white/less_tiring_sneakers_off_white_2", alt: "Less Tiring Sneakers Off White context 2 view" },
				],
				price: 202,
				review: 4.7,
				review_nbr: 268,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#fdf8f8",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "37", price: 0 },
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "42", price: 0 },
							{ name: "43", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
		]
	},
	{
		name: "Organic Cotton Sneakers",
		derivates: [
			{
				name: "Organic Cotton Sneakers Black",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_7", alt: "Organic Cotton Sneakers Black main view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_1", alt: "Organic Cotton Sneakers Black side view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_2", alt: "Organic Cotton Sneakers Black from under view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_3", alt: "Organic Cotton Sneakers Black back view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_4", alt: "Organic Cotton Sneakers Black front view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_5", alt: "Organic Cotton Sneakers Black inside view" },
					{ src: "organic_cotton_sneakers/black/organic_cotton_black_6", alt: "Organic Cotton Sneakers Black zoom view" },
				],
				price: 231,
				review: 0.8,
				review_nbr: 206,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "39", price: 0 },
							{ name: "43", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Organic Cotton Sneakers Off White",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_6", alt: "Organic Cotton Sneakers Off White main view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_1", alt: "Organic Cotton Sneakers Off White side view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_2", alt: "Organic Cotton Sneakers Off White from under view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_3", alt: "Organic Cotton Sneakers Off White back view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_4", alt: "Organic Cotton Sneakers Off White front view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_5", alt: "Organic Cotton Sneakers Off White inside view" },
					{ src: "organic_cotton_sneakers/off_white/organic_cotton_off_white_7", alt: "Organic Cotton Sneakers Off White context view" },
				],
				price: 170,
				review: 3.2,
				review_nbr: 100,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#0f0f0f",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "38", price: 0 },
							{ name: "39", price: 0 },
							{ name: "41", price: 0 },
							{ name: "44", price: 0 },
						],
						default_value: "40",
					},
				],
			},
			{
				name: "Organic Cotton Sneakers Red Pattern",
				description: "Comfortable and casual canvas sneakers with a cushioned insole for all-day support. Made with organic cotton, the fabric is treated with a water-repellent finish making these shoes suitable for rainy days.",
				images_src: [
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_7", alt: "Organic Cotton Sneakers Red Pattern main view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_1", alt: "Organic Cotton Sneakers Red Pattern side view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_2", alt: "Organic Cotton Sneakers Red Pattern from under view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_3", alt: "Organic Cotton Sneakers Red Pattern back view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_4", alt: "Organic Cotton Sneakers Red Pattern front view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_5", alt: "Organic Cotton Sneakers Red Pattern inside view" },
					{ src: "organic_cotton_sneakers/red_pattern/organic_cotton_red_pattern_6", alt: "Organic Cotton Sneakers Red Pattern zoom view" },
				],
				price: 80,
				review: 1.6,
				review_nbr: 196,
				sharedOptions: [{
					name: "Color",
					type: "color",
					value: "#9f3746",
				}],
				options: [
					{
						name: "Size",
						type: "size",
						values: [
							{ name: "39", price: 0 },
							{ name: "45", price: 0 },
						],
						default_value: "40",
					},
				],
			},
		]
	},
]
