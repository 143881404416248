export const round = (value: number, step: number) => {
    step || (step = 1.0);
    var inv = 1.0 / step;
    return Math.round(value * inv) / inv;
}

export const roundDown = (value: number, step: number) => {
	step || (step = 1.0);
	var inv = 1.0 / step;
  return Math.round((value * inv) - step) / inv;
}
