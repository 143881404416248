import React from "react";
import ColorSelection from "./ColorSelection";

type ColorInputRadioT = {
	idValueList: {id: string, value: string}[],
	currentId: string,
}

export const ColorInputRadio = ({idValueList, currentId}: ColorInputRadioT) => {

	const allValuesElements = idValueList.map((option, i) => {
		const isCurrent = option.id === currentId;
		return (<React.Fragment key={i}>
			<input
				type="radio"
				checked={isCurrent}
				id={option.value}
				name="id"
				value={option.id}
				onChange={() => {}}
			/>
			<ColorSelection
				color={option.value}
				isCurrent={isCurrent}
				id={option.id}
			>
				<label htmlFor={option.value}></label>
			</ColorSelection>
		</React.Fragment>);
	});
	
	return (
		<div className="color-selector" id="color-options">
			{allValuesElements}
		</div>
	);
}
