import React, { createContext, useContext } from "react";

type BreadcrumbProviderProps = {
	children?: React.ReactNode,
	page: string,
}

export const BreadcrumbContext = createContext<string[]>([]);

const BreadcrumbProvider = ({ children, page = "Error" }: BreadcrumbProviderProps) => {
	const breadcrumb = useContext(BreadcrumbContext);
	
	// TODO: check page
	return (
		<BreadcrumbContext.Provider value={breadcrumb.concat([page])}>
			{children}
		</BreadcrumbContext.Provider>
	);
}

export default BreadcrumbProvider;
