import ProductDescription from "../components/productDescription/ProductDescription";
import ProductCardList from "../components/productList/ProductList";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import BreadcrumbProvider from "../providers/breadcrumbProvider";
import { ActionFunctionArgs, ParamParseKey, Params, redirect, useLoaderData } from "react-router-dom";
import { getProduct, getSubProduct, getSubProducts } from "../dataManipulation/products";
import { ProductObj, SubProductObj } from "../dataManipulation/types"
import { PaginationProvider } from "../providers/paginationProvider";
import "./ProductDetails.css";
import "./page.css"
import { NavigationButtons } from "../components/pagination/Navigation";
import { useEffect, useRef, useState } from "react";
import "@/effects/effects";
import ScrollMe from "@/components/scrollMe/ScrollMe";
import { productsSortingOptions } from "@/dataManipulation/sortProducts";
import { useScreenSize } from "@/providers/screenSizeProvider";
import { getPageProperty } from "@/dataManipulation/page";
import { useThemeData } from "@/providers/themeProvider";

const Paths = {
	productId: "/shop/:productId",
} as const;

interface LoaderArgs extends ActionFunctionArgs {
	params: Params<ParamParseKey<typeof Paths.productId>>;
}

export const loader = async ({ params }: LoaderArgs) => {
	const product = await getSubProduct(params.productId ?? "");
	if (!product) return(redirect(getPageProperty("Shop")?.routerUrl));
	const allProducts = await getSubProducts();
	if (!product || !allProducts) {
		throw new Response("", {
			status: 404,
			statusText: "Not Found",
		});
	}
	const parentProduct = await getProduct(product.parentId)
	return { product, parentProduct, allProducts };
}

const RelatedProductsList = () => {
	return (
		<>
			<div className="related-products-header">
				<h2>Related Products</h2>
				<div className="related-produtcs-page-control">
					<NavigationButtons />
				</div>
			</div>
			<ProductCardList />
		</>
	);
}

interface ProductDetailsContentProps {
	product: SubProductObj,
	parentProduct: ProductObj,
}

const ProductDetailsContent = ({ product, parentProduct }: ProductDetailsContentProps) => {
	const ref = useRef<HTMLDivElement>(null)
	const [prevProd, setPrevProd] = useState(product);
	const { theme } = useThemeData();

	useEffect(() => {
		if (prevProd != product) {
			ref.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
			setPrevProd(product);
		}
	}, [product])
	
	return (
		<>
			<div className="page-background" style={{ backgroundColor: theme.colors.third }}/>
			<ScrollMe />
			<div className="header">
				<Breadcrumb />
				<h2 className="title">Shop</h2>
			</div>
			<div className='page-content' ref={ref}>
				<ProductDescription product={product} parentProduct={parentProduct} />
				<RelatedProductsList />
			</div>
		</>
	);
}

const ProductDetails = () => {
	const { product, parentProduct, allProducts } = useLoaderData() as { product: SubProductObj, parentProduct: ProductObj, allProducts: SubProductObj[] };
	const { screenWidth } = useScreenSize();

	const relatedProductNbr = screenWidth <= 500 ? 2 : screenWidth <= 900 ? 3 : 4;

	return (
		<div className="Details page">
			<PaginationProvider
				productPerPage={relatedProductNbr}
				newProducts={allProducts.filter((p: SubProductObj) => p.id != product.id)}
				defaultSort="High to low"
				sortingOptions={productsSortingOptions}
			>
				<BreadcrumbProvider page={"Shop"}>
					<BreadcrumbProvider page={"Product Details"}>
						<ProductDetailsContent
							parentProduct={parentProduct}
							product={product} />
					</BreadcrumbProvider>
				</BreadcrumbProvider>
			</PaginationProvider>
		</div>
	);
}

export default ProductDetails;
