import { usePaginationAPI, usePaginationData } from "../../providers/paginationProvider";
import arrowLeft from "/src/assets/svg/arrow-left.svg";
import arrowRight from "/src/assets/svg/arrow-right.svg";
import "./Navigation.css"

export const NavigationButtons = () => {
	const { nextPage, previousPage } = usePaginationAPI();
	const { hasPreviousPage, hasNextPage} = usePaginationData();

	const handlePrevClick = () => hasPreviousPage && previousPage();
	const handleNextClick = () => hasNextPage && nextPage();
	
	return (
		<div className="product-navigation-container">
			<div className={"product-navigation prev" + (hasPreviousPage ? "" : " disabled")} onClick={handlePrevClick}>
				<img className="logo-arrow" src={arrowLeft} alt="left arrow" />
				<p>Prev</p>
			</div>
			<div className={"product-navigation next" + (hasNextPage ? "" : " disabled")} onClick={handleNextClick}>
				<p>Next</p>
				<img className="logo-arrow" src={arrowRight} alt="right arrow" />
			</div>
		</div>
	);
}
