type IPage = {
	routerUrl: string,
	name: string,
}

const pages: IPage[] = [
	{	routerUrl: "/",	name: "Home" },
	{ routerUrl: "/shop", name: "Shop" },
	{ routerUrl: "/shop/:productId", name: "Product Details" },
	{ routerUrl: "/blog", name: "Blog" },
	{ routerUrl: "/Contact", name: "Contact" },
	{ routerUrl: "/shop/cart", name: "Cart" },
]

export const getPageProperty = (name: string) => {
	const found = pages.find((page) => page.name === name);
	return (found !== undefined ? found : {routerUrl: "/notfound", name: "Not found"})
}
