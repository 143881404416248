import Nav from '../components/nav/Nav';
import { Outlet } from 'react-router-dom';
import BreadcrumbProvider from "../providers/breadcrumbProvider";
import { ScreenSizeProvider } from '@/providers/screenSizeProvider';
import { ThemeProvider, useThemeData } from '@/providers/themeProvider';

const RootContent = () => {
	const { theme } = useThemeData();

	return (
		<div className="app-background" style={{ backgroundColor: theme.colors.main }}>
			<div className='App noSelect' style={{
				backgroundColor: theme.colors.second,
				color: theme.textColors.main,
				...{ "--swiper-pagination-color": theme.colors.third } as React.CSSProperties,
			}}>
				<div className='App-container'>
					<Nav />
					<Outlet />
				</div>
			</div>
		</div>
	);
}

function Root() {

	return (
		<ScreenSizeProvider>
			<ThemeProvider themeName={"light"}>
				<BreadcrumbProvider page={"Home"}>
					<RootContent />
				</BreadcrumbProvider >
			</ThemeProvider>
		</ScreenSizeProvider>
	)
}

export default Root;
