import optionReOrder from "../../dataManipulation/options";
import { ProductObj } from "../../dataManipulation/types";
import { capitalize } from "../../utils/string";
import { getProductSharedProperty } from "../../dataManipulation/products";
import { ColorInputRadio } from "./ColorInputRadio";
import { ReactElement } from "react";

interface SharedOptionSelectionProps {
	parentProduct: ProductObj,
	currentIdProduct: string,
}

// For the "onChange={() => {}}", without it a warning is generated on the fact checked is provided without onChange. Compiler doesn't know about onSelection...
const SharedOptionSelection = ({ parentProduct, currentIdProduct }: SharedOptionSelectionProps) => {
	const typeOrder = ["color"];
	const optionList = parentProduct.derivates.find((subP) => subP.id === currentIdProduct);
	
	if (optionList && optionList.sharedOptions) {
		const orderedOptList = optionReOrder(typeOrder, optionList.sharedOptions);

		return (
			<>
				{orderedOptList &&
					orderedOptList.map((option, i) => {
						const idAndValues = getProductSharedProperty(parentProduct, option).idAndValues as {id: string, value: string}[];
						let optionSelector: ReactElement;
						switch (option) {
							case "color":
								optionSelector = <ColorInputRadio idValueList={idAndValues} currentId={currentIdProduct} />
								break;
							default:
								optionSelector = <></>
								console.log("unknown option");
						}
						return (
							<div className="Description-property" key={i}>
								<h4 className="Product-description-attribute-name">{capitalize(option)}:</h4>
								{optionSelector}
							</div>
						)
					})
				}
			</>
		);
	}
}

export default SharedOptionSelection;
