import React, { createContext, useContext, useEffect, useState } from 'react';
import themeConf from "@/assets/conf/themes.json"

type ColorObj = {
	main: string,
	second: string,
	third?: string,
	fourth?: string,
}

type ThemeObj = {
	name: string,
	colors: ColorObj,
	textColors: ColorObj,
}

type API = {
	setTheme: (themeName: string) => void,
}

type Data = {
	theme: ThemeObj,
}

const ThemeDataContext = createContext<Data>({} as Data);
const ThemeAPIContext = createContext<API>({} as API);

const defaultTheme: ThemeObj = {
	name: "light",
	colors: {
		main: "#FAF9F4",
		second: "#E2DFCC",
		third: "#C7C19C",
		fourth: "#878EBC",
	},
	textColors: {
		main: "#213547",
		second: "#75757f",
	}
}

const extractThemeConf = (file: typeof themeConf) => {
	try {
		const themes: ThemeObj[] = file;
		return themes
	} catch (e) {
		if (typeof e === "string") {
        e.toUpperCase() 
		}
    else if (e instanceof SyntaxError) {
			console.log(e.message)
		}
		return null;
	}
}

export const ThemeProvider = ({ children, themeName }: { children: React.ReactNode, themeName: string }) => {
	const [theme, setTheme] = useState<ThemeObj>(defaultTheme)
	const [themes, setThemes] = useState<ThemeObj[]>(extractThemeConf(themeConf) ?? [defaultTheme])

	const changeTheme = (themeName: string) => {
		const newTheme = themes.find(th => th.name === themeName);
		setTheme(newTheme === undefined ? defaultTheme : newTheme)
	}

	useEffect(() => {
		changeTheme(themeName);
	}, [themeName])
	
	return (
		<ThemeAPIContext.Provider value={{setTheme: changeTheme}}>
			<ThemeDataContext.Provider value={{theme: theme}}>
				{children}
			</ThemeDataContext.Provider>
		</ThemeAPIContext.Provider>
	);
}

export const useThemeData = () => useContext(ThemeDataContext);
export const useThemeAPI = () => useContext(ThemeAPIContext);
