import { SubProductObj } from "./types";

type IProductsSortingOption = {
	name: string,
	fct: (products: SubProductObj[]) => SubProductObj[],
}

const lowToHigh = (products: SubProductObj[]) => {
	const sortedProducts = products.slice().sort((a, b) => a.price - b.price)
	return (sortedProducts);
}

const highToLow = (products: SubProductObj[]) => {
	const sortedProducts = products.slice().sort((a, b) => b.price - a.price)
	return (sortedProducts);
}

const mostRecent = (products: SubProductObj[]) => {
	const sortedProducts = products.slice().sort((a, b) => b.createdAt - a.createdAt)
	return (sortedProducts);
}

export const productsSortingOptions: IProductsSortingOption[] = [
	{ name: "Most Recent", fct: mostRecent },
	{ name: "Low to high", fct: lowToHigh },
	{ name: "High to low", fct: highToLow },
]
