import gsap from "gsap"

gsap.registerEffect({
  name: "fadeIn",
  effect: (target: GSAPTweenTarget, config: GSAPTimelineVars | GSAPTweenVars) => {
    return gsap.from(target, {
      duration: config.duration,
			autoAlpha: 0,
			ease: config.ease,
    });
  },
  defaults: {duration: 0.5, ease: "sine.in"},
  extendTimeline: true
});

gsap.registerEffect({
  name: "placeholder",
  effect: (target: GSAPTweenTarget, config: GSAPTimelineVars | GSAPTweenVars) => {
    return gsap.to(target, {
			backgroundColor: config.backgroundColor,
      duration: config.duration,
			ease: config.ease,
			autoAlpha: 0,
			yoyo: true,
			repeat: -1,
    });
  },
  defaults: {backgroundColor: "cyan", duration: 1, ease: "sine.in"},
  extendTimeline: true
});
