
type PlaceholderProps = {
	sizes: number[],
	unit: string,
	style: React.CSSProperties,
}

const Placeholder = ({ sizes, unit, style }: PlaceholderProps) => {
	return sizes.map((size, i) => {
		return (<span key={i} style={{ ...style, width: `${size}${unit}` }}></span>)
	})
}

export default Placeholder;
