import "./index.css";
import BreadcrumbProvider from "../providers/breadcrumbProvider";

const Index = () => {
	return (
		<div className="Index">
			<BreadcrumbProvider page="Home">
				<div>
					<h5>Welcome to my simple <span>E-Commerce</span> website!</h5>
					<p>I've been developping this website to pratice my front-end skills.</p>
					<h4>Todo:</h4>
					<ul>
						<li>Add payement support (Stripe?)</li>
						<li>Link to CMS</li>
						<li>Create content for all pages
							<ul>
								<li>Home</li>
								<li>Contact</li>
								<li>Blog</li>
							</ul>
						</li>
					</ul>
					<h3>Check out the shop</h3>
					<p className="author">developed by <a href="https://www.linkedin.com/in/thomas-delmas-17b1b9136/">Thomas Delmas</a></p>
				</div>
			</BreadcrumbProvider>
		</div>
	);
}

export default Index;
