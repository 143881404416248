import { roundDown } from "../utils/number";
import Rating from "./rating/rating";
import { emptyStar, halfStar, fullStar } from "@/assets/stars";

const ProductReview = ({ review, reviewNbr }: { review: number, reviewNbr: number }) => {
	const reviewRound = roundDown(review, 0.5);
	
	return (
		<>
			{review && reviewNbr &&
				<div className="PD-review">
					<h4 className="PD-attribute-name">Review:</h4>
					<Rating
						review={reviewRound}
						maxGrade={5}
						assetPath={{
							empty: emptyStar,
							half: halfStar,
							full: fullStar,
						}}
					/>
					<span className="PD-review-rating">{review}</span>
					<span className="PD-review-number">{"(" + reviewNbr + ")"}</span>
				</div>
			}
		</>
	);
}

export default ProductReview;
