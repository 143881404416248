import React, { useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import "./ColorSelection.css";

interface ColorSelectionProps {
	children?: React.ReactNode,
	color: string,
	isCurrent: boolean,
	id: string,
}

const ColorSelection = ({ children, color, isCurrent, id }: ColorSelectionProps) => {
	const container = useRef<HTMLDivElement>(null);
	const timeline = useRef<GSAPTimeline>();

	const borderColor = color + '99';
	const lightColor = color + '10';
	const sizeOutRound = 40;
	const sizeInRound = sizeOutRound % 2 === 1 ? (sizeOutRound / 2) - 1 : sizeOutRound / 2;
	const wrapperStyle = {
		width: `${sizeOutRound}px`,
		height: `${sizeOutRound}px`,
		backgroundColor: lightColor,
	}
	const borderStyle = {
		width: `${sizeInRound - 2}px`,
		height: `${sizeInRound - 2}px`,
		border: (isCurrent) ? `1px solid ${borderColor}` : "none",
	};
	const innerStyle = {
		width: `${sizeInRound}px`,
		height: `${sizeInRound}px`,
		backgroundColor: `${color}`,
	}

	useGSAP(() => {
		if (isCurrent) {
			timeline.current = gsap.timeline({ repeat: -1, repeatDelay: 1 })
				.to(".inner-round", { backgroundColor: borderColor, duration: 0.5, ease: "sine.inOut" })
				.to(".inner-round", { backgroundColor: color, duration: 0.5, ease: "sine.inOut" })
				.to(".option-value-border", { scale: 2, duration: 0.5, ease: "sine.inOut" }, "-=0.6")
				.to(".option-value-border", { border: "1px solid transparent", duration: 0.5, ease: "power1.out" }, "-=0.3");
		}

	}, { dependencies: [isCurrent], scope: container, revertOnUpdate: true })

	return (
		<Link to={`/shop/${id}`} onClick={e => isCurrent && e.preventDefault()}>
			<div ref={container} className="option-value-wrapper" style={wrapperStyle}>
				<div className="option-value-border" style={borderStyle}></div>
				<div className="inner-round" style={innerStyle}></div>
				{children}
			</div>
		</Link >
	);
}

export default ColorSelection;
