import { OptionObj, SharedOptionObj } from "./types";

const optionReOrder = (order: string[], options: (SharedOptionObj | OptionObj)[]) => {
	let sortedOptions = order;

	if (options) {
		const unorderedRemainingOptions = options.filter((option) => !order.some((type) => type === option.type))
		sortedOptions.push(...unorderedRemainingOptions.map((option) => option.type));
	}
	
	return sortedOptions;
}

export default optionReOrder;
