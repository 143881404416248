import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import './gallery.css';
import React, { useEffect, useRef, useState } from 'react';
import fetchImage from "@/utils/fetchImage";

interface imageGalleryProps<T> {
	imageData: T,
	setCurImage: (imageData: T) => void
}

const ImageGallery = <T extends { src: string, alt: string }>({ imageData, setCurImage }: imageGalleryProps<T>) => {
	const [loaded, setLoaded] = useState(false);
	const [image, setImage] = useState<string>();
	const animContainer = useRef<HTMLImageElement>(null);

	useEffect(() => {
		fetchImage(imageData.src + "_x75")
			.then(img => setImage(img))
	}, [imageData])
	
	useGSAP(() => {
		if (loaded) {
			gsap.effects.fadeIn(".gallery-image", { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
		}
	}, { scope: animContainer, dependencies: [loaded], revertOnUpdate: true })

	return (
		<div className="gallery-image-wrapper noSelect" ref={animContainer}>
			<img
				className="gallery-image noSelect"
				src={image}
				alt={imageData.alt}
				onLoad={() => setLoaded(true)}
				onClick={() => setCurImage(imageData)}
				style={{ visibility: "hidden" }} />
		</div>
	)
}

interface GalleryProps<T> {
	imagesData: T[],
	setCurImage: (imageData: T) => void,
}

const Gallery = <T extends {src: string, alt: string}>({ imagesData, setCurImage }: GalleryProps<T>) => {

	return (
		<>
			{imagesData && imagesData.length > 0 ?
				<div className="gallery">
					{imagesData.map((imageData, i) => {
						return (
							<React.Fragment key={i}>
								<ImageGallery imageData={imageData} setCurImage={setCurImage} />
							</React.Fragment>
						)
					})}
				</div>
				:
				<div>No images</div>
			}
		</>
	);
}

export default Gallery;
