import { useContext } from "react";
import { BreadcrumbContext } from "../../providers/breadcrumbProvider";
import { Link } from "react-router-dom";
import { getPageProperty } from "../../dataManipulation/page";
import './Breadcrumb.css'

const Breadcrumb = () => {
	const breadcrumbArray = useContext(BreadcrumbContext);

	const breadcrumb = breadcrumbArray.map((page, i, arr) => {
		if (arr.length == i + 1) {
			return (<span key={i} className="breadcrumb-current-page">{page}</span>);
		}
		return (<span key={i}><Link to={getPageProperty(page)?.routerUrl || "/404"}>{page + " / "}</Link></span>);
	});

	return (
		<div className="breadcrumb">
			{breadcrumb}
		</div>
	);
}

export default Breadcrumb;
