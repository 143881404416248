import { useThemeData } from "@/providers/themeProvider";

interface OptionSelectionButtonProps<T> {
	children?: React.ReactNode,
	value: T,
	status: string,
	onSelection: (name: T) => void,
} 

const OptionSelectionButton = <T,>({children, value, status, onSelection}: OptionSelectionButtonProps<T>) => {
	const { theme } = useThemeData();
	let color;

	if (status == "not-available") {
		color = theme.colors.main;
	} else if (status == "current") {
		color = theme.colors.third;
	} else {
		color = theme.colors.second;
	}

	return (
		<div
			className={"option-value noSelect " + status}
			style={{ backgroundColor: color }}
			onClick={() => status === "available" && onSelection(value)}
		>
			{children}
		</div>
	);
}

export default OptionSelectionButton;
