import React, { useCallback, useEffect, useRef, useState } from "react";
import { PaginationDataContextType, usePaginationData } from "../../providers/paginationProvider";
import { SubProductObj } from "../../dataManipulation/types";
import { Link } from 'react-router-dom';
import './ProductList.css'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { surroundWordSpan } from "@/utils/surroundWordSpan";
import { useScreenSize } from "@/providers/screenSizeProvider";
import fetchImage from "@/utils/fetchImage";
import useOutsideClickListener from "@/hooks/useOutsideClickListener";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useThemeData } from "@/providers/themeProvider";

type ProductCardInfoProps = {
	name: string,
	price: number,
	loaded?: boolean,
	addSubTimeline?: (subTimeline: GSAPTimeline) => void,
}

const ProductCardInfoUnder = ({ name, price, loaded }: ProductCardInfoProps) => {
	const { theme } = useThemeData();
	const spanStyle = { background: `linear-gradient(66deg, ${theme.colors.fourth} -30%, ${theme.colors.main} -15%, ${theme.colors.fourth} 0%)` }
	
	return (
		<div
			className="Product-card-info-container under"
			style={{ color: !loaded ? "transparent" : "inherit" }}
		>
			<div className={"Product-card-name under" + (!loaded ? " text-placeholder" : " text-loaded")}>{surroundWordSpan(name, spanStyle)}</div>
			<div className={"Product-card-price under" + (!loaded ? " text-placeholder" : " text-loaded")}><span style={spanStyle}>${price}.00</span></div>
		</div>
	);
}

const ProductCardInfoOver = ({ name, price, loaded, addSubTimeline }: ProductCardInfoProps) => {
	const nameRef = useRef<HTMLDivElement | null>(null);
	const priceRef = useRef<HTMLDivElement | null>(null);
	const { theme } = useThemeData();
	const spanStyle = { background: `linear-gradient(66deg, ${theme.colors.fourth} -30%, ${theme.colors.main} -15%, ${theme.colors.fourth} 0%)` }

	useGSAP(() => {
		if (loaded) {
			const tl = gsap.timeline({ id: "over" })
				.to([nameRef.current, priceRef.current], { opacity: "100%", duration: 0.3, ease: "power1.inOut" })
				.to(nameRef.current, { y: "100%", duration: 0.3, ease: "power1.inOut", immediateRender: false }, "<")
				.to(priceRef.current, { y: "-160%", duration: 0.3, ease: "power1.inOut", immediateRender: false }, "<");

			addSubTimeline && addSubTimeline(tl);
		}
	}, { dependencies: [addSubTimeline, loaded]})

	return (
		<>
			<div ref={nameRef} className={"Product-card-name over" + (!loaded ? " text-placeholder" : " text-loaded")} style={{ color: !loaded ? "transparent" : "inherit" }}>{surroundWordSpan(name, spanStyle)}</div>
			<div ref={priceRef} className={"Product-card-price over" + (!loaded ? " text-placeholder" : " text-loaded")} style={{ color: !loaded ? "transparent" : "inherit" }}><span>${price}.00</span></div>
		</>
	);
}

type ProductCardImageProps<T> = {
	imageData: T,
	loaded: boolean,
	setLoaded?: (loaded: boolean) => void,
}

const ProductCardImage = <T extends { src: string, alt: string }>({ imageData, loaded, setLoaded }: ProductCardImageProps<T>) => {
	const [image, setImage] = useState<string | null>(null);

	useEffect(() => {
		if (setLoaded) {
			setLoaded(false);
		}
		setImage(null);
		fetchImage(imageData.src + "_x384")
			.then(img => setImage(img))
	}, [imageData])

	return (
		<>
			{image &&
				<img
					className="Product-card-image noSelect"
					draggable="false"
					loading="lazy"
					src={image}
					alt={imageData.alt}
					style={{ visibility: loaded ? "inherit" : "hidden" }}
					onLoad={() => setLoaded && setLoaded(true)} />
			}
		</>
	);
}

type ProductCardProps = {
	product: SubProductObj,
}

const ProductCard = ({ product }: ProductCardProps) => {
	const imgData = product.images_src[0].src;
	const [loaded, setLoaded] = useState(!imgData ? true : false);
	const animContainer = useRef<HTMLDivElement>(null);
	const tl = useRef<GSAPTimeline | null>(null)
	const [isReveal, setIsReveal] = useState(false);
	const { theme } = useThemeData();

	useGSAP(() => {
		if (loaded) {
			gsap.from([".Product-card-image", ".Product-card-info-container.under"], { autoAlpha: 0, duration: 0.7, ease: "power1.in" })
		}
	}, { dependencies: [loaded], scope: animContainer })

	const { contextSafe } = useGSAP(() => {
		tl.current = gsap.timeline({ paused: true, id: "tl" })
	}, { scope: animContainer, revertOnUpdate: true });

	// useGSAP(() => {
	// 	if (loaded) {
	// 		tl.current?.add(gsap.to(".Product-card-gallery", { scale: 1.1, duration: 0.3, ease: "power1.inOut" }), 0);
	// 	}
	// }, { dependencies: [loaded], scope: animContainer, revertOnUpdate: true })

	const addSubTimeline = useCallback((subTimeline: GSAPTimeline) => {
		tl.current?.add(subTimeline, 0)
	}, [tl.current])

	const onReveal = contextSafe(() => tl.current?.play());
	const onHide = contextSafe(() => tl.current?.reverse());


	const { screenWidth } = useScreenSize();

	const handleOnClick = (e: React.MouseEvent | React.TouchEvent) => {
		if (!isReveal && screenWidth <= 767) {
			e.preventDefault();
			e.stopPropagation();

			setIsReveal(true);
			onReveal();
		}
	}

	const handleOnMouseEnter = () => {
		setIsReveal(true);
		onReveal();
	}

	const handleHide = () => {
		if (isReveal) {
			setIsReveal(false);
			onHide();
		}
	}

	useOutsideClickListener(animContainer, handleHide)

	return (
		<Link to={`/shop/${product.id}`} className="Product-card-container noSelect" draggable="false">
			<div
				className="Product-card-content"
				ref={animContainer}
				style={{ backgroundColor: theme.colors.main, boxShadow: "inset 0 0 2px 0 #fff7f1" }}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleHide}
				onClick={(e) => handleOnClick(e)}
			>
				{imgData ?
					<div className="img-wrapper">
						<div className="img-placeholder" style={{ background: `linear-gradient(66deg, ${theme.colors.fourth} -30%, ${theme.colors.main} -15%, ${theme.colors.fourth} 0%)`, visibility: !loaded ? "visible" : "hidden" }}></div>
						<Swiper
							lazyPreloadPrevNext={2}
							pagination={{
								dynamicBullets: true,
							}}
							modules={[Pagination]}
							className="mySwiper"
						>
							{screenWidth <= 767 &&
								<ProductCardInfoOver
									name={product.name}
									price={product.price}
									loaded={loaded}
									addSubTimeline={addSubTimeline}
								/>}
							{product.images_src.map((imageData, i) => {
								return (
									<SwiperSlide key={i}>
										<ProductCardImage imageData={imageData} loaded={loaded} setLoaded={setLoaded} />
									</SwiperSlide>
								)
							})}
						</Swiper>
					</div>
					:
					<div>No image.</div>
				}
				{!(screenWidth <= 767) &&
					<ProductCardInfoUnder
						name={product.name}
						price={product.price}
						loaded={loaded}
						addSubTimeline={addSubTimeline}
					/>}
			</div>
		</Link>
	);
}


const ProductCardList = () => {
	const { pageProducts } = usePaginationData() as PaginationDataContextType<SubProductObj>;
	const ref = useRef<HTMLDivElement>(null);
	const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
	const { theme } = useThemeData();
	
	const mainColor = theme.colors.fourth;
	const secColor = theme.colors.main;

	useGSAP(() => {
		tl.to([".img-placeholder", ".under.text-placeholder>span"], {
			background: `linear-gradient(66deg, ${mainColor} 100%, ${secColor} 115%, ${mainColor} 130%)`,
			duration: 1.7,
			ease: "power1.inOut",
		})
	}, { scope: ref, revertOnUpdate: true })

	return (
		<div className="App-list-product" ref={ref}>
				{pageProducts.map((product, i) => {
					return (
						<React.Fragment key={i}>
							<ProductCard product={product}/>
						</React.Fragment>
					);
				})}
		</div>
	);
}

export default ProductCardList;

// For srcSet
					// (product.images_src[0].src === "sneaker-classic-0-shadow-card-scale" ?
					// 	<div className="img-wrapper">
					// 		<div className="img-placeholder" style={{ backgroundColor: "inherit", visibility: !loaded ? "visible" : "hidden" }}></div>
					// 		<img
					// 			className="Product-card-image"
					// 			sizes={image.size}
					// 			srcSet={image.srcSet}
					// 			src={image.src}
					// 			alt={product.images_src[0].alt}
					// 			style={{ visibility: "hidden" }}
					// 			onLoad={() => setLoaded(true)} />
					// 	</div>
					// 	:
