import React, { useEffect } from "react";

const useOutsideClickListener = (ref: React.RefObject<HTMLElement>,	handler: ((event: MouseEvent | TouchEvent | KeyboardEvent) => void) | null) => {
	useEffect(() => {

		if (handler) {
			const handleClickOutside = (event: MouseEvent | TouchEvent) => {
				if (
					ref.current &&
					!ref.current.contains(event.target as Node)
				) {
					handler(event);
				}
			};

			const handleEscapeKey = (event: KeyboardEvent) => {
				if (event.key === 'Escape') {
					handler(event);
				}
			};

			document.addEventListener('click', handleClickOutside, true);
			// document.addEventListener('touchstart', handleClickOutside);
			document.addEventListener('keydown', handleEscapeKey);

			return () => {
				document.removeEventListener('click', handleClickOutside, true);
				// document.removeEventListener('touchstart', handleClickOutside);
				document.removeEventListener('keydown', handleEscapeKey);
			};
		}
	}, [ref, handler]);
};

export default useOutsideClickListener;
