import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import "./ScrollMe.css";
import { useRef } from "react";

const ScrollMe = () => {
	const container = useRef<HTMLDivElement>(null)

	useGSAP(() => {
		gsap.fromTo(container.current, { opacity: 1 }, { opacity: 0.5, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true });
	}, { scope: container })
	
	return (
		<div className="scroll-me" ref={container}>
			<h5>Scroll me</h5>
			<div className="scroll-track scroll-bar">
				<div className="scroll-view scroll-bar"></div>
			</div>
		</div>
	);
}

export default ScrollMe;
