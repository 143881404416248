import { CartProductObj, getProductOptionsPrice, useCartAPI, useCartData } from "../providers/cartProvider";
import './CartSummary.css';
import Card from "../components/card/Card";
import { useThemeData } from "@/providers/themeProvider";
import React, { useEffect, useRef, useState } from "react";
import fetchImage from "@/utils/fetchImage";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

type CartProductProps = {
	product: CartProductObj,
	priceWithOptions: number,
}

const CartProduct = ({ product, priceWithOptions }: CartProductProps) => {
	const { changeProductQuantity, removeFromCart } = useCartAPI();
	const [image, setImage] = useState<string>();
	const [loaded, setLoaded] = useState(false);
	const animContainer = useRef<HTMLImageElement>(null);
	const { theme } = useThemeData();

	useEffect(() => {
		fetchImage(product.images_src[0].src + "_x384")
			.then(img => setImage(img))
	}, [product])

	useGSAP(() => {
		if (loaded) {
			gsap.effects.fadeIn(".Cart-product-img", { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
		}
	}, { scope: animContainer, dependencies: [loaded], revertOnUpdate: true })

	return (
		<div className="Cart-product" ref={animContainer}>
			<div className="Cart-product-details">
			<div className="Cart-product-img-wrapper" style={{ backgroundColor: theme.colors.main }}>
					{product.images_src[0] ?
						<img className="Cart-product-img noSelect"
							alt={product.images_src[0].alt}
							src={image}
							onLoad={() => setLoaded(true)}
							style={{ visibility: "hidden" }}
						/>
						:
						<div>No image</div>
					}
				</div>
				<div className="Cart-product-description">
					<div className="Cart-product-name">{product.name}</div>
					<div className="Cart-product-options">
						{product.options?.map((option, i) => <div className="Cart-product-option" key={i} style={{ backgroundColor: theme.colors.second, border: "1px solid " + theme.colors.main }}>{option.type} {option.value}</div>)}
					</div>
				</div>
			</div>
			<div className="Cart-product-control">
				<div className="Cart-product-info">
					<div className="Cart-product-price">Price: {priceWithOptions}</div>
					<div className="Cart-product-quantity">Quantity: {product.quantity}</div>
					<div className="Cart-product-control-quantity">
						<button className="Cart-product-control-sub" onClick={() => changeProductQuantity(-1, product.id)}>-</button>
						<button className="Cart-product-control-add" onClick={() => changeProductQuantity(1, product.id)}>+</button>
					</div>
				</div>
			</div>
			<button className="Cart-product-remove" onClick={() => removeFromCart(product.id)}>x</button>
		</div>
	);
}

const CartProductList = () => {
	const { cartProducts } = useCartData();
	const { theme } = useThemeData();
	
	return (
		<ul className="Cart-product-list" style={{ backgroundColor: theme.colors.third }}>
			{cartProducts.map((product, i) => {
				if (product) {
					const priceWithOptions = product.price + getProductOptionsPrice(product);
					return (
						<React.Fragment key={i}>
							<CartProduct product={product} priceWithOptions={priceWithOptions} />
						</React.Fragment>
					)
				}
			})}
		</ul>
	);	
}

const CartTotal = () => {
	const { cartTotalPrice } = useCartData();
	const { theme } = useThemeData();
	
	return (
		<div>
			<div className="Cart-total" style={{ backgroundColor: theme.colors.third }}>
				<h2 className="Cart-total-title">Total</h2>
				<h4 className="Cart-total-price">{cartTotalPrice}</h4>
			<button className="Cart-total-checkout" style={{ backgroundColor: theme.colors.main }}>Checkout</button>
			</div>
		</div>
	);
}

const CartSummary = () => {
	const { cartProducts } = useCartData();
	const { theme } = useThemeData();

	return (
		<section>
			{cartProducts.length > 0 ?
				<div className="Cart">
					<CartProductList />
					<CartTotal />
				</div>
				:
				<div className="empty-cart-message" style={{ backgroundColor: theme.colors.third }}>No item in cart... yet!</div>
			}
		</section>
	);
}

export default CartSummary;
