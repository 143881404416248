import { useRef, useState } from "react";
import useOutsideClickListener from "../../hooks/useOutsideClickListener";
import arrowDown from "/src/assets/svg/simple-triangle-arrow-down-normal.svg";
import "./Accordion.css"
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

const updateOtherItems = <T,>(items: T[], currentItem: T) => {
	const otherItems = items.filter((item) => item !== currentItem)
	if (otherItems)
		return otherItems;
	return [currentItem];
}

interface AccordionProps<T> {
	items: T[],
	onSelection: (item: T) => void,
	defaultItem: T
}

export const Accordion = <T extends string | number>({items, onSelection, defaultItem}: AccordionProps<T>) => {
	const [selec, setSelec] = useState<T>(defaultItem);
	const [isOpen, setIsOpen] = useState(false);
	const [otherItems, setOtherItems] = useState<T[]>(updateOtherItems(items, defaultItem));
	const outsideContainerRef = useRef<HTMLDivElement>(null);
	const otherItemsContainerRef = useRef<HTMLDivElement>(null);
	const arrowRef = useRef<HTMLImageElement>(null);
  const timeline = useRef<GSAPTimeline>();


	const { contextSafe } = useGSAP(() => {
		timeline.current = gsap.timeline({ paused: true })
			.to(otherItemsContainerRef.current, { height: "auto", duration: 0.3, ease: "power3.inOut" })
			.to(arrowRef.current, { rotation: 90, duration: 0.3, ease: "power3.inOut" }, "<");
	}, [arrowRef, otherItemsContainerRef]);

	const onOpenAnimation = contextSafe(() => timeline.current?.play());
	const onCloseAnimation = contextSafe(() => timeline.current?.reverse());

	const onClickOutside = (e: MouseEvent | TouchEvent | KeyboardEvent) => {
		if (isOpen) {
			// To prevent other Clickable element to be triggered
			e.stopPropagation();
			// To prevent anchor tag from Link of ReactRouter to be triggered and "hard" navigate the link
			e.preventDefault();
		}
		setIsOpen(false);
		onCloseAnimation();
	}
	
	useOutsideClickListener(outsideContainerRef, onClickOutside)

	const handleClick = (item: T) => {
		if (isOpen) {
			if (item !== selec) {
				setSelec(item);
				onSelection(item);
				setOtherItems(updateOtherItems(items, item));
			}
			setIsOpen(false);
			onCloseAnimation();
		}
		else {
			setIsOpen(true);
			onOpenAnimation();
		}
	}

	const arrowLogoSizePx = 7;
	const arrowMarginLeftPx = 10
	const styleArrowPlaceholder = {
		width: `${arrowLogoSizePx}px`,
		height: `${arrowLogoSizePx}px`,
		marginLeft: `${arrowMarginLeftPx}px`
	};

	return (
		<div className="accordion">
				<div ref={outsideContainerRef}>
					<button onClick={() => handleClick(selec)}>
						<div>{selec}</div>
						<img className="accordion-arrow" ref={arrowRef} style={styleArrowPlaceholder} src={arrowDown} />
					</button>
					<div ref={otherItemsContainerRef} className="other-items" style={{ height: 0 }}>
						{
							otherItems.map((item, i) => {
								return (
									<button key={i} value={item} onClick={() => handleClick(item)}>
										<div>{item}</div>
										<div style={styleArrowPlaceholder}></div>
									</button>
								);
							})
						}
					</div>
				</div>
		</div>
	);
}
