import { useEffect, useRef, useState } from "react";
import { ProductObj, SubProductObj } from "../../dataManipulation/types";
import { Form } from "react-router-dom";
import { useCartAPI } from "../../providers/cartProvider";
import Gallery from "../gallery/gallery";
import "./ProductDescription.css"
import "./ProductDescriptionSize.css"
import ProductReview from "../productReview";
import SharedOptionSelection from "../productOptions/SharedOptionSelection";
import OptionSelection from "../productOptions/OptionSelection";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import "@/effects/effects"
import fetchImage from "@/utils/fetchImage";
import { useScreenSize } from "@/providers/screenSizeProvider";
import { ProductDescriptionLargePlaceholder, ProductDescriptionMediumPlaceholder, ProductDescriptionSmallPlaceholder } from "@/components/productDescription/placeholder";
import { useThemeData } from "@/providers/themeProvider";

type ImageAndPriceProductProps = {
	image: string,
	price: number;
}

const ImageAndPriceProduct = ({image, price}: ImageAndPriceProductProps) => {
	const animContainer = useRef<HTMLDivElement>(null);
	const [loaded, setLoaded] = useState(false);

	useGSAP(() => {
		if (loaded) {
			gsap.effects.fadeIn([".PD-img", ".Product-card-info-container"], { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
		}
	}, { dependencies: [image, loaded], scope: animContainer, revertOnUpdate: true })
	
	return (
		<div className="PD-img-wrapper" ref={animContainer}>
			{image ?
					<img
						className="PD-img"
						alt={"test"}
						src={image}
						onLoad={() => setLoaded(true)}
						style={{ visibility: "hidden" }}
					/>
				:
				<div>No image</div>
			}
			<div className="Product-card-info-container" style={{ visibility: "hidden" }}>
				<h3 className="PD-price">${price}.00</h3>
			</div>
		</div>
	);
}

type FormAddToCartProps = {
	product: SubProductObj,
}

const FormAddToCart = ({ product }: FormAddToCartProps) => {

	const formRef = useRef<HTMLFormElement>(null);
	const { addProductToCart } = useCartAPI();
	const { theme } = useThemeData();

	const onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (formRef.current) {
			const formData = new FormData(formRef.current);
			const productOptions = Object.fromEntries(formData) as { id: string };

			addProductToCart(product.id, productOptions, 1);
		}
	}

	return (
		<Form ref={formRef} autoComplete="off" method="post" className="PD-form" onSubmit={onSubmit} >
			<div className="PD-options">
				<OptionSelection product={product} />
			</div>
			<button type="submit" className="add-to-cart" style={{ backgroundColor: theme.colors.third }}>Add to cart</button>
		</Form>
	);
}

type ProductDescriptionVariantProps = {
	product: SubProductObj,
	parentProduct: ProductObj,
	img: string,
	setCurImage: (curImage: { src: string, alt: string }) => void;
}

const ProductDescriptionLarge = ({product, parentProduct, img, setCurImage}: ProductDescriptionVariantProps) => {
	const ref = useRef<HTMLDivElement>(null);
	
	useGSAP(() => {
		gsap.effects.fadeIn(ref.current, { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { scope: ref, revertOnUpdate: true })
	
	return (
		<div className="PD-container large" ref={ref}>
			<div className="PD-large-zoneA">
				<div>
					<h2 className="PD-name">{product.name || "No name"}</h2>
					<ProductReview review={product.review} reviewNbr={product.review_nbr} />
					<p className="PD-description">{product.description || "No description"}</p>
				</div>
			</div>
			<div className="PD-large-zoneB">
				<ImageAndPriceProduct
					image={img}
					price={product.price}
				/>
				<Gallery<{ src: string, alt: string }>
					imagesData={product.images_src}
					setCurImage={setCurImage}
				/>
			</div>
			<div className="PD-large-zoneC">
				{parentProduct &&
					<SharedOptionSelection parentProduct={parentProduct} currentIdProduct={product.id} />
				}
				<FormAddToCart product={product} />
			</div>
		</div>
	);
}

const ProductDescriptionMedium = ({product, parentProduct, img, setCurImage}: ProductDescriptionVariantProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState(37);
	
	useGSAP(() => {
		gsap.effects.fadeIn(ref.current, { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { scope: ref, revertOnUpdate: true })
	
	return (
		<div className="PD-container medium" ref={ref}>
			<div className="PD-medium-zoneA">
				<div>
					<h2 className="PD-name">{product.name || "No name"}</h2>
					<ProductReview review={product.review} reviewNbr={product.review_nbr} />
					<p className="PD-description">{product.description || "No description"}</p>
				</div>
				<div>
					{parentProduct &&
						<SharedOptionSelection parentProduct={parentProduct} currentIdProduct={product.id} />
					}
					<FormAddToCart product={product} />
				</div>
			</div>
			<div className="PD-medium-zoneB">
				<ImageAndPriceProduct
					image={img}
					price={product.price}
				/>
				<Gallery<{ src: string, alt: string }>
					imagesData={product.images_src}
					setCurImage={setCurImage}
				/>
			</div>
		</div>
	);
}

const ProductDescriptionSmall = ({product, parentProduct, img, setCurImage}: ProductDescriptionVariantProps) => {
	const ref = useRef<HTMLDivElement>(null);
	
	useGSAP(() => {
		gsap.effects.fadeIn(ref.current, { autoAlpha: 0, duration: 0.3, ease: "sine.in" })
	}, { scope: ref, revertOnUpdate: true })
	
	return (
		<div className="PD-container small" ref={ref}>
			<div className="PD-small-zoneA">
				<h2 className="PD-name">{product.name || "No name"}</h2>
				<div className="PD-images">
					<ImageAndPriceProduct
						image={img}
						price={product.price}
					/>
					<Gallery<{ src: string, alt: string }>
						imagesData={product.images_src}
						setCurImage={setCurImage}
					/>
				</div>
			</div>
			<div className="PD-small-zoneB">
				<ProductReview review={product.review} reviewNbr={product.review_nbr} />
				<p className="PD-description">{product.description || "No description"}</p>
				{parentProduct &&
					<SharedOptionSelection parentProduct={parentProduct} currentIdProduct={product.id} />
				}
				<FormAddToCart product={product} />
			</div>
		</div>
	);
}

type ProductDescriptionProps = {
	product: SubProductObj,
	parentProduct: ProductObj,
}

const ProductDescription = ({ product, parentProduct }: ProductDescriptionProps) => {

	const [prevProduct, setPrevProduct] = useState(product);
	const [curImage, setCurImage] = useState(product.images_src[0]);
	const [img, setImg] = useState<string | null>(null)
	const { screenWidth } = useScreenSize();
	const variantBreakpoints = [
		{ screenWidth: 1200 },
		{ screenWidth: 767 },
		{ screenWidth: 0 },
	];
	const { theme } = useThemeData();

	let isPending = img === null;
	
	if (product !== prevProduct) {
		isPending = true;
		setImg(null);
		setPrevProduct(product);
	}

	useEffect(() => {
		setCurImage(product.images_src[0]);
	}, [product])

	useEffect(() => {
		fetchImage(curImage.src + "_2000x2000")
			.then(img => setImg(img))
	}, [curImage])

	const variantSelection = (img: string | null) => {
		const variant = variantBreakpoints.findIndex((brk) => screenWidth > brk.screenWidth)

		switch (variant) {
			case 0:
				if (img && !isPending)
					return (
						<ProductDescriptionLarge
							product={product}
							parentProduct={parentProduct}
							img={img}
							setCurImage={setCurImage}
						/>
					);
				return (<ProductDescriptionLargePlaceholder />)
			case 1:
				if (img && !isPending)
					return (
						<ProductDescriptionMedium
							product={product}
							parentProduct={parentProduct}
							img={img}
							setCurImage={setCurImage}
						/>
					);
				return (<ProductDescriptionMediumPlaceholder />)
			case 2:
				if (img && !isPending)
					return (
						<ProductDescriptionSmall
							product={product}
							parentProduct={parentProduct}
							img={img}
							setCurImage={setCurImage}
						/>
					);
				return (<ProductDescriptionSmallPlaceholder />)				
		}
	}

	return (
		<div className="Product-description" style={{ backgroundColor: theme.colors.main }}>
			{variantSelection(img)}
		</div>
	);
}

export default ProductDescription;
