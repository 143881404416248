import React from "react";
import './rating.css'

type starsAssetPathObj = {
	empty: string,
	half: string,
	full: string,
}

const Star = ({ nbr, review, assetPath }: { nbr: number, review: number, assetPath: starsAssetPathObj }) => {

	return (
		<>
			<div className="Star-container">
				<img className="Star empty" alt="star" src={assetPath.empty} />
				{review - (nbr) >= 1 ?
					<img className="Star full" alt="star" src={assetPath.full} />
					:
					review - nbr > 0 &&
					<img className="Star half" alt="half star" src={assetPath.half} />
				}
			</div>

		</>
	);
}

const Rating = ({ review, maxGrade = 5 , assetPath }: { review: number, maxGrade?: number, assetPath: starsAssetPathObj }) => {
	const stars = [...Array(maxGrade)].map((star, i) => <React.Fragment key={i}><Star assetPath={assetPath} nbr={i} review={review} /></React.Fragment>)

	return (
		<div className="Rating">
			{stars}
		</div>
	)
}

export default Rating;
