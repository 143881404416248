import OptionSelectionButton from "./optionSelectionButton";

interface InputRadioProps<T> {
	status: string,
	formPropertyName: string,
	value: T,
	onSelection: (name: T) => void,
}

// For the "onChange={() => {}}", without it a warning is generated on the fact checked is provided without onChange. Compiler doesn't know about onSelection...
const InputRadio = <T extends number | string>({status, formPropertyName, value, onSelection}: InputRadioProps<T>) => {
	const isChecked = (status === "current")
	const valueName = (typeof value === "number") ? value.toString() : value as string;

	return (
		<>
			<input
				type="radio"
				checked={isChecked}
				id={valueName}
				name={formPropertyName}
				value={valueName}
				onChange={() => {}}
			/>
			<OptionSelectionButton<T>
				value={value}
				status={status}
				onSelection={onSelection}
			>
				<label className={status} htmlFor={valueName}>{valueName}</label>
			</OptionSelectionButton>
		</>
	);
}

export default InputRadio;
