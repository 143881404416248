import React from 'react'
import ReactDOM from 'react-dom/client'
import Root from './routes/root'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Shop, { loader as shopLoader } from './routes/Shop'
import ProductDetails, { loader as productDetailsLoader } from './routes/ProductDetails'
import { CartProvider } from './providers/cartProvider';
import CartSummary from './routes/CartSummary';
import Index from './routes/index'
import { getPageProperty } from './dataManipulation/page'
import './App.css'

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path={getPageProperty("Home")?.routerUrl}
			element={<Root />}
		>
			<Route index element={<Index />} />
			<Route
				path={getPageProperty("Shop")?.routerUrl}
				element={<Shop />}
				loader={shopLoader}
			/>
			<Route
				path={getPageProperty("Product Details")?.routerUrl}
				element={<ProductDetails />}
				loader={productDetailsLoader}
			/>
			<Route
				path={getPageProperty("Cart")?.routerUrl}
				element={<CartSummary />}
			/>
		</Route>
	)
);


ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<CartProvider>
			<RouterProvider router={router} />
		</CartProvider>
	</React.StrictMode>,
)
